import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { injectIntl } from 'react-intl';

import * as actions from '../../store/actions/index';
import Widget from './components/Widget/Widget';
import messages from './Dashboard.intl';
import { AuthContext } from '../../context/AuthContext';
import PageTemplate from '../../templates/PageTemplate/PageTemplate';
import Tabs from '../../components/Tabs/Tabs';
import HealthTable from './components/HealthTable';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = ({
    widgets: initialWidgets,
    session,
    moduleSettingsNFS,
    onInitWidgets,
    onGetEmailToNotify,
    intl,
}) => {
    const { hasClaim } = useContext(AuthContext);
    const [mode, setMode] = useState('static');
    const [widgets, setWidgets] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (e, index) => {
        if (activeTab !== index) setActiveTab(index);
    };

    useEffect(() => {
        const contractorId = session.contractorId;
        if (contractorId) {
            onGetEmailToNotify(contractorId);
        }
    }, [session]);

    useEffect(() => {
        if (hasClaim('widget-view')) {
            onInitWidgets();
        }
    }, [hasClaim, onInitWidgets]);

    useEffect(() => {
        const localStorageLayout = getFromLocalStorage('layout');
        const originalLayout =
            JSON.parse(JSON.stringify(localStorageLayout || '')) || [];

        const newWidgets = initialWidgets.map(w => {
            const layout = originalLayout.find(l => l.i === w.widgetId);
            let widgetLayout = layout || w.layout;

            widgetLayout = widgetLayout || {
                i: w.widgetId,
                ...w.layout,
            };

            return {
                ...w,
                layout: {
                    ...widgetLayout,
                    isDraggable: false,
                    isResizable: false,
                },
            };
        });

        setWidgets(newWidgets);
    }, [initialWidgets]);

    const saveToLocalStorage = (key, value) => {
        if (global.localStorage) {
            global.localStorage.setItem(
                'DASHBOARD',
                JSON.stringify({
                    [key]: value,
                }),
            );
        }
    };

    const getFromLocalStorage = key => {
        let ls = {};
        if (global.localStorage) {
            try {
                ls = JSON.parse(global.localStorage.getItem('DASHBOARD')) || {};
            } catch (e) {
                return null;
            }
        }
        return ls[key];
    };

    const handleGridLayoutChange = layout => {
        const newLayout = layout.map(l => {
            let id = '';
            const split = l.i.split('_');
            if (split.length > 0) {
                id = split[0];
            }

            return {
                ...l,
                i: id,
            };
        });

        saveToLocalStorage('layout', newLayout);
    };

    const handleEditSaveDashboardBtnClick = () => {
        if (mode === 'static') {
            const editableWidgets = widgets.map(w => ({
                ...w,
                layout: {
                    ...w.layout,
                    isDraggable: true,
                    isResizable: true,
                },
            }));

            setMode('edit');
            setWidgets(editableWidgets);
        } else {
            const localStorageLayout = getFromLocalStorage('layout');
            const originalLayout = JSON.parse(
                JSON.stringify(localStorageLayout),
            );

            const staticWidgets = widgets.map(w => {
                const layout = originalLayout.find(l => l.i === w.widgetId);
                const widgetLayout = layout || w.layout;

                return {
                    ...w,
                    layout: {
                        ...widgetLayout,
                        isDraggable: false,
                        isResizable: false,
                    },
                };
            });

            setMode('static');
            setWidgets(staticWidgets);
        }
    };

    const readOnly = mode === 'edit';

    const layout = widgets.map(w => ({
        i: w.widgetId,
        ...w.layout,
    }));

    return (
        <PageTemplate noBorder>
            <Tabs
                handleTabClick={handleTabClick}
                activeTab={activeTab}
                tabs={[
                    {
                        name: intl.formatMessage(messages.healthTitle),
                        disabled:
                            !moduleSettingsNFS?.enableMonitoringDashboard &&
                            !hasClaim('health-nfs-view'),
                        component: <HealthTable />,
                    },
                    {
                        name: intl.formatMessage(messages.headerTitle),
                        component: (
                            <>
                                <button
                                    className='dashboard-edit-button'
                                    onClick={handleEditSaveDashboardBtnClick}
                                >
                                    {mode === 'static'
                                        ? intl.formatMessage(
                                              messages.headerButtonEdit,
                                          )
                                        : intl.formatMessage(
                                              messages.headerButtonSave,
                                          )}
                                </button>
                                {mode === 'edit' && (
                                    <Alert
                                        color='warning'
                                        style={{ fontSize: '16px' }}
                                    >
                                        {intl.formatMessage(
                                            messages.editModeAlertText,
                                        )}
                                    </Alert>
                                )}
                                <ResponsiveGridLayout
                                    compactType={'vertical'}
                                    layout={layout}
                                    onLayoutChange={handleGridLayoutChange}
                                    className='dashboard-grid'
                                >
                                    {widgets.map(widget => {
                                        let classWidget = 'widget ';
                                        if (widget.class !== '') {
                                            classWidget +=
                                                widget.class + '-widget';
                                        }

                                        return (
                                            <div
                                                key={`${
                                                    widget.widgetId
                                                }_${mode}`}
                                                data-grid={widget.layout}
                                                className={classWidget}
                                            >
                                                <Widget
                                                    {...widget}
                                                    readOnly={readOnly}
                                                />
                                            </div>
                                        );
                                    })}
                                </ResponsiveGridLayout>
                            </>
                        ),
                    },
                ]}
            />
        </PageTemplate>
    );
};

const mapStateToProps = state => ({
    widgets: state.widgetReducer.widgets,
    session: state.authenticationReducer.session,
    moduleSettingsNFS: state.settingsReducer.moduleSettingsNFS,
});

const mapDispatchToProps = dispatch => ({
    onInitWidgets: () => dispatch(actions.initWidgets()),
    onGetEmailToNotify: contractorId =>
        dispatch(actions.getEmailToNotify(contractorId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(Dashboard));
